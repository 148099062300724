import React, { Component } from "react"
import "./Indicator.css"
class Indicator extends Component {
  findIcon = status => {
    switch (status) {
      case "failure":
        return "fal fa-times-circle"
      case "success":
        return "fal fa-check-circle"
      default:
        return "fal fa-circle-notch fa-spin"
    }
  }
  render() {
    let { status } = this.props
    return <i className={`indicator ${status} ${this.findIcon(status)}`}></i>
  }
}

export default Indicator
