import React, { Component } from "react"
import "./App.css"
import Checker from "./Checker.js"
import "promise-polyfill/src/polyfill"

class App extends Component {
  render() {
    return (
      <div className="App container">
        <div className="row">
          <div className="logo-container mb-5 pb-5 mb-md-0 pb-md-0 col-12 col-md-6">
            <img src="bc-logo.svg" className="logo" alt="logo" />
          </div>

          <div className="col-12 col-md-6 px-5 px-md-0">
            <Checker
              title="CDN 1"
              domain="//balancedcomp-cdn.s3.amazonaws.com"
              toWhitelist="balancedcomp-cdn.s3.amazonaws.com"
            />
            <Checker
              title="CDN 2"
              domain="https://cdn.balancedcomp.io/results/css/public.min.css"
              toWhitelist="cdn.balancedcomp.io"
            />
            <Checker
              title="PDF Server"
              domain="https://puppeteer.balancedcomp.net"
              toWhitelist="puppeteer.balancedcomp.net"
            />
            <Checker
              title="API"
              domain="https://dev.balancedcomp.net/network_test"
              toWhitelist="balancedcomp.net"
            />
            <Checker
              title="Attachments"
              domain="//files.balancedcomp.io/netcheck.txt"
              toWhitelist="files.balancedcomp.io"
            />
            <Checker
              title="Fonts"
              domain="https://use.typekit.net/dhh4vdw.css"
              toWhitelist="use.typekit.net"
            />
            <Checker
              title="Icons"
              domain="https://kit.fontawesome.com/43d4b7622d.js"
              toWhitelist="kit.fontawesome.com"
            />

            <Checker
              title="Support/Knowledge Base"
              domain="https://js.hs-scripts.com/20125216.js"
              toWhitelist="js.hs-scripts.com"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default App
