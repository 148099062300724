import React, { Component } from "react"
import axios from "axios"

import "./Checker.css"
import Indicator from "./Indicator.js"
class Checker extends Component {
  constructor(props) {
    super(props)
    this.state = { status: "unchecked" }
  }
  checkDomain = url => {
    this.setState({ status: "checking" })
    axios
      .get(url)
      .then(response => {
        this.setState({ status: "success" })
      })
      .catch(error => {
        console.log(url, error)
        this.setState({ status: "failure" })
      })
  }
  componentDidMount() {
    this.checkDomain(this.props.domain)
  }
  render() {
    let { title, toWhitelist } = this.props
    let { status } = this.state

    return (
      <div className={`checker ${status} `}>
        <Indicator status={status} />
        <div className="checker-label">
          <div className="title">{title}</div>
          <div className="domain">{toWhitelist}</div>
        </div>
      </div>
    )
  }
}

export default Checker
